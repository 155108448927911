import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { css } from 'styled-components'
import { Helmet } from 'react-helmet'

import config from '../../utils/siteConfig'
import { media } from '../../utils/media'

import SEO from '../../components/SEO'
import Link from '../../components/Link'

import { PortfolioCard } from '../../components/Card/index'
import HeroNobackground from '../../components/HeroNoBackground'
import { MainLayout } from '../../components/MainLayout'
import { space } from '../../utils/tokens'

const Portfolio = () => {
  const data = useStaticQuery(query)
  const { hero, slug, title } = data.contentfulPage
  const postNode = data.contentfulPage

  return (
    <div>
      <MainLayout>
        <SEO pagePath={slug} postNode={postNode} pageSEO />
        <Helmet>
          <title>{`${title} - ${config.siteTitle}`}</title>
          <meta name="robots" content="noindex" />
        </Helmet>
        <section
          css={css({
            paddingTop: `${space[5]}px`,
            paddingBottom: `${space[5]}px`,
          })}
        >
          <HeroNobackground hero={hero} />
          <div
            css={css`
              padding: 0 1rem;
              display: grid;
              grid-gap: 1rem;
              grid-auto-rows: 400px;

              ${media.desktop`
              max-width: 1440px;
              padding: 0 2rem;
              margin: auto;
              grid-template-columns: repeat(6, 1fr);
              grid-auto-rows: 325px;
              grid-gap: 1rem;

              > * {
                grid-column: span 2;
              }

              > :nth-child(1) {
                grid-column: 1 / 5;
                grid-row: 1 / 3;
              }

              > :nth-child(4) {
                grid-row: span 2;
              }

              > :nth-child(n + 4) {
                grid-column: span 2;
                grid-row: span 2;
              }
            `}
            `}
          >
            {data.allContentfulPortfolioWork.edges.map(project => {
              return (
                <Link key={project.node.id} to={project.node.fields.path}>
                  <PortfolioCard portfolio={project.node} />
                </Link>
              )
            })}
          </div>
        </section>
      </MainLayout>
    </div>
  )
}

export default Portfolio

const query = graphql`
  query {
    contentfulPage(slug: { eq: "portfolio" }) {
      hero {
        ...HeroFragment_withNoBackground
      }
      title
      slug
      metaDescription {
        internal {
          content
        }
      }
    }
    allContentfulPortfolioWork(sort: { order: DESC, fields: createdAt }) {
      edges {
        node {
          ...PortfolioCardFragment_withCategories
          fields {
            path
          }
        }
      }
    }
  }
`
