import { Link as GatsbyLink } from 'gatsby'
import styled from 'styled-components'

const StyledLink = styled(GatsbyLink)`
  text-decoration: none;

  :visited {
    color: inherit;
  }
`


export default StyledLink